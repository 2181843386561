<template>
    <div>
        <h3>Collection Accounts</h3>
        <psi-simple-table
            :items="collectionAccounts"
            :headers="headers"
        ></psi-simple-table>
    </div>
</template>
<script>
export default {
    name: "credit-collection-accounts",
    components: {},
    props: {
        collections: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        collectionAccounts() {
            return this.collections.map((item) => {
                item.currentBalance = this.$dollarFormat(
                    this.$_.get(item, "currentBalance", 0)
                );
                return item;
            });
        },
        headers() {
            return Object.keys(this.collections[0]).map((key) => {
                return {
                    title: this.knownTitles(key),
                    class: "text-left",
                    key,
                };
            });
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        knownTitles(key) {
            switch (key) {
                case "collectionAgencyName":
                    return "Collection Agency Name";
                case "creditorsName":
                    return "Creditors Name";
                case "currentBalance":
                    return "Current Balance";
                case "dateOpened":
                    return "Date Opened";
                case "remarksCode":
                    return "Remarks Code";
            }
        },
    },
};
</script>

<style scoped>
</style>