import { render, staticRenderFns } from "./CreditCollectionAccounts.vue?vue&type=template&id=188791fd&scoped=true&"
import script from "./CreditCollectionAccounts.vue?vue&type=script&lang=js&"
export * from "./CreditCollectionAccounts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "188791fd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('188791fd')) {
      api.createRecord('188791fd', component.options)
    } else {
      api.reload('188791fd', component.options)
    }
    module.hot.accept("./CreditCollectionAccounts.vue?vue&type=template&id=188791fd&scoped=true&", function () {
      api.rerender('188791fd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/screen/components/Credit/CreditCollectionAccounts.vue"
export default component.exports